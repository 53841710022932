.project {
  background-color: #f9f9f9;
  padding: 15rem 0;
  margin-bottom: 2rem;
}

.project .container {
  margin: 0 auto;
  max-width: 107rem;
  padding: 0 4rem;
}

.project h2,
.project h3 {
  color: #147efb;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.projectCard {
  width: 100%;
  min-height: 500px;
  box-shadow: 0px 5px 5px black;
  padding: 1rem;
  margin-bottom: 3rem;
  line-height: 3.5rem;
}
.projectCard .imageContainer {
  width: 100%;
  height: 350px;
  margin-bottom: 1.4rem;
}

.project1ImageContainer {
  background-image: url(../../ImageAssets/Prompt-Quest.png);
  background-size: cover;
  background-position: top center;
}
.project2ImageContainer {
  background-image: url(../../ImageAssets/commerceApp.png);
  background-size: cover;
  background-position: top center;
}
.project3ImageContainer {
  background-image: url(../../ImageAssets/gym-app.png);
  background-size: cover;
  background-position: top center;
}
.project4ImageContainer {
  background-image: url(../../ImageAssets/car-rental-app.png);
  background-size: cover;
  background-position: top center;
}

.project p {
  color: #767676;
  font-family: Mulish, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.5;
}

.projectCard a {
  text-decoration: none;
  color: #147efb;
  font-size: 1.2rem;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .projectCard .imageContainer {
    height: 120px;
    background-size: cover;
  }
  .projectCard {
    height: auto;
    font-size: 1rem;
    line-height: 2rem;
  }
}
