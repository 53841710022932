.home {
  background-color: #f9f9f9;
  padding: 3rem;
  margin-bottom: 3rem;
}
.hero-main {
  align-items: center;
  display: flex;
  gap: 10rem;
  justify-content: center;
  position: relative;
}
.hero-text {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  position: relative;
  line-height: 2.5rem;
}
.hero-text h1 {
  color: #2d2e32;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.2;
  margin-top: 2rem;
}
.hero-text p img {
  transform: translateY(5rem);
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.hero-text p {
  color: #555;
  font-family: Mulish, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}
.hero-text span {
  cursor: pointer;
  display: flex;
  gap: 1.3rem;
  margin: 2.5rem 0;
}
.hero-text span a {
  transition: all 0.2s;
  color: #2d2e32;
  font-size: 2rem;
}
.hero-img {
  background-image: url(../../ImageAssets/profile.jpg);
  -webkit-animation: morph 8s ease-in-out infinite;
  animation: morph 8s ease-in-out infinite;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid #2d2e32;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  height: 26rem;
  position: relative;
  transition: all 1s ease-in-out;
  width: 35rem;
  margin-bottom: 3rem;
}
@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.skills {
  align-items: center;
  color: #767676;
  display: flex;
  gap: 2rem;
  font-size: 1.3rem;
}
.skills p {
  border-right: 2px solid rgba(45, 46, 50, 0.5);
  color: #2d2e32;
  font-family: Mulish, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}
.skills ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  flex-wrap: wrap;
}
.skills ul li {
  cursor: pointer;
}
.skills ul li img {
  height: 3rem;
  transition: all 0.3s ease-in-out;
  width: auto;
}

.skills ul li img:hover {
  transform: translateY(-5px);
}

@media (max-width: 900px) {
  .hero-main {
    flex-direction: column-reverse;
    gap: 3rem;
    height: auto;
    text-align: center;
  }
  .hero-text span {
    gap: 1.6rem;
    justify-content: center;
  }

  .hero-img {
    height: 30rem;
    width: 25rem;
  }

  .skills {
    flex-direction: column;
    position: static;
  }
  .skills ul {
    justify-content: center;
  }
  .skills p {
    border-bottom: 2px solid rgba(45, 46, 50, 0.5);
  }
}
@media (max-width: 500px) {
  .hero-img {
    height: 20rem;
    width: 15rem;
  }
}
