* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-color: #8e8e8e #fff;
  scrollbar-width: thin;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  font-family: Poppins, sans-serif;
  min-height: 100vh;
  width: 100%;
}
